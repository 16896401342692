@import "settings";

.post-header {
  h2 {
    margin: 0 auto!important;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $h2;
    font-weight: $extra;
    padding: 90px 0 90px 0;

  }

  .content {
    text-align: center!important;
    padding: 55px 20px 55px 20px;
    margin: 0 10px 0 10px;
    &:hover,
    &:focus {
      -webkit-box-shadow: 8px 7px 12px -9px rgba(0,0,0,0.15);
      -moz-box-shadow: 8px 7px 12px -9px rgba(0,0,0,0.15);
      box-shadow: 8px 7px 12px -9px rgba(0,0,0,0.15);
      transition: .5s;
    }

    h3 {
      font-family: $font;
      color: $black;
      font-size: $h3;
      font-weight: $bold;
      padding: 20px 15px 0px 15px;

    }

    p {
      font-family: $font;
      color: $lGrey;
      font-size: $p1;
      font-weight: $light;
    }

    .number {
      margin: 0;
      position: absolute;
      transform: translate(-50%, 15%);
      left: 50%;
    }
  }

  .content-sub {
    text-align: center!important;
    padding: 55px 20px 55px 20px;
    margin: 0 10px 0 10px;
    h3 {
      font-family: $font;
      color: $black;
      font-size: $h3;
      font-weight: $bold;
      padding: 20px 15px 0px 15px;

    }

    p {
      font-family: $font;
      color: $lGrey;
      font-size: $p1;
      font-weight: $light;
    }

    .number {
      margin: 0;
      position: absolute;
      transform: translate(-50%, 15%);
      left: 50%;
    }
  }
}

.post-header-top {
  margin-top:250px;
  margin-bottom:50px;
  .p-text {
    margin: 0 auto!important;
    max-width: 880px;
    color: $black;
    text-align: center;
    font-family: $font;
    font-weight: $regular;
    font-size: $p-text;
    padding: 50px 0 0 0;
  }
}