@import "settings";
.pre-contact {
  padding: 120px 0 70px 0;
  .p-text {
    margin: 0 auto;
    max-width: 840px;
    color: $black;
    text-align: center;
    font-family: $font;
    font-weight: $regular;
    font-size: $p-text;
    padding: 20px 0 20px 0;
    line-height: 30px;
  }
}
.contact {
  margin-top: 60px;
  z-index: 1!important;
}
.form-info {
  margin-top: 70px;
  h2 {
    margin: 0 auto!important;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $h2;
    font-weight: $extra;
  }

  p {
    margin: 15px 0 15px;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $p2;
    font-weight: $bold;
  }

  .item {
    text-align: center;
    padding-bottom: 25px;
    img {
      text-align: center;
      margin: 70px 0 0;
    }

    .h6-headline {
      padding: 10px 0 0;
      font-size: $p1!important;
      font-family: $font!important;
      font-weight: $bold!important;
    }
    p {
      font-size: $p1!important;
      color: $ColorB;
      font-weight: $light!important;
      margin: 5px 0 5px;
    }

    a {
      font-size: $p1!important;
      color: $ColorB;
      font-weight: $light!important;
      margin: 5px 0 5px;
    }
  }
}

.form-section {
  background: $white;
  z-index: 1!important;
  font-family: $font;
  padding-top: 60px;
  padding-right: 90px;
  padding-left: 90px;
  -webkit-box-shadow: 0px 2px 17px -1px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 2px 17px -1px rgba(0,0,0,0.15);
  box-shadow: 0px 2px 17px -1px rgba(0,0,0,0.15);

  /*==========================
    radio buttons
    ==========================
*/
  .form-check,
  .ex-radio {
    position: relative;
    margin: 0.7em 0;
    font-size: 1em;
  }

  .form-check {
    padding-left: 0!important;
    margin-top: 10px;
  }
  .form-check label,
  .ex-radio label {
    padding-left: 32px;
    line-height: 140%;
    font-weight: normal;
    display: inline-block;
    position: relative;
  }

  .form-check input[type=checkbox],
  .ex-radio input[type=radio] {
    margin: 0;
    opacity: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
  }

  /*---------------------------*/

  .form button {
    border-radius: 6px;
    border: 0 none;
    background: #0065ae!important;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    margin-top: 35px;
  }
  .form-check input {
    background: black;
  }

  textarea {
    resize: none;
  }
  .form-label {
    font-size: 1em;
    font-family: $font;
    color: $black;
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: .333s ease top, .333s ease opacity;
    transition: .333s ease top, .333s ease opacity;
  }
  .form-control {
    border-radius: 0;
    border-color: #ccc;
    border-width: 0 0 2px 0;
    border-style: none none solid none;
    box-shadow: none;
    padding: 0;
  }

  .form-group {
    .btn {
      background: $ColorA!important;
      padding: 12px 50px 12px 50px;
      color: #ffffff;
      font-weight: 700;
      border-radius: 50px!important;
      &:hover,
      &:focus {
        background: #fed7d5!important;
        color: $ColorA;
        transition: all .8s ease-in-out;
      }
    }
  }
  /*.form-group .btn {
    font-family: $font;
    font-size: 1em;
    display: inline-block;
    margin: 1em 0 1em 0;
    text-transform: uppercase;
    padding: 0.6em 2.4em;
    background: none!important;
    position: relative;
    transition:color 0.5s ease;
    border-radius: 0px;
    color: $black;
    border:2px solid $ColorA;
  }

  .form-group .btn:hover {
    color: white;
  }

  .form-group .btn::after {
    position: absolute;
    content: '';
    top:0;
    left: 0;
    width: 0;
    height: 100%;
    transform-origin:left;
    transition:width 0.5s ease;
    z-index:-1;
    background-color: $ColorA!important;
  }

  .form-group .btn:hover::after {
    width: 100%;
  }*/


  .submit {
    text-align: left!important;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    border: 0px none;
  }
  /* ----------- STAR --------------------*/

  input[type=submit] {
    width: auto;
  }

  input[form-group] + label {
    color: #999;
    font-family: $font;
    font-size: .8em;
    position: relative;
    left: -166px; /* the negative of the input width */
  }

  input[form-group] + label:after {
    content:'*';
    color: red;
  }

  /* show the placeholder when input has no content (no content = invalid) */
  input[form-group]:invalid + label {
    display: inline-block;
  }

  /* hide the placeholder when input has some text typed in */
  input[form-group]:valid + label{
    display: none;
  }


  /* --------------------------------------*/

  .form-control:focus {
    box-shadow: none;
    border-color: #000000;
    width: 100%;
    transition: 2s;
  }
  .js-hide-label {
    opacity: 0;
  }
  .js-unhighlight-label {
    color: #999;
    font-family: $font;
  }
  .btn-start-order {
    background: 0 0 #000000;
    border: 1px solid #2f323a;
    border-radius: 3px;
    color: #2f323a;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: inherit;
    margin: 30px 0;
    padding: 10px 50px;
    text-transform: uppercase;
    transition: all 0.25s ease 0s;
  }
  .btn-start-order:hover,.btn-start-order:active, .btn-start-order:focus {
    border-color: #5e9bfc;
    color: #5e9bfc;
  }

}

@media (max-width: 500px) {
  .form-section {
    padding-left: 15px!important;
    padding-right: 15px!important;
  }
}

@media (max-width: 350px) {
  .form-section {
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
}