$font: 'Open Sans', sans-serif;

$lGrey: #878787;
$black: #000;
$white: #fff;
$ColorA: #fc5751;
$ColorB: #a6a6a6;

$p1: 20px;
$p2: 24px;

$h2: 50px;
$h3: 30px;

$title: 40px;
$post-title: 30px;
$p-text: 20px;


$extra: 800;
$bold: 700;
$semi: 600;
$regular: 400;
$light: 300;