@import "settings";

.footer {
  z-index: 0!important;
  margin-top: -80px;
  background: $black;
  padding: 150px 0 0 0;
}

.top-container {
  border-bottom: 2px solid $white!important;
  padding-right: 0!important;
  padding-left: 0!important;
  .row {
    margin-right: 0!important;
    margin-left: 0!important;
  }
}

.ft-nav {
  text-align: right!important;
  padding: 0!important;
}

.footer-nav {
  list-style: none;
  padding-left: 0;
  padding-right: 0;

  .nav-item {
    display: inline-block;
    text-align: right;
    margin-left: 0!important;
    margin-right: 0!important;

    a {
      text-align: right!important;
      font-family: $font;
      color: $white;
      font-size: 1em;
      font-weight: $bold;
    }
  }
}

.social-nav {
  list-style: none;
  float: right;
  li {
    display: inline-block;
  }
}

.bottom-container {
  padding-left: 0px!important;
  padding-right: 0px !important;
  padding-top: 35px;

  .row {
    margin-right: 0!important;
    margin-left: 0!important;
  }

  p {
    color: $white;
    font-family: $font;
    font-weight: $light;
    font-size: 16px;
    padding-left: 0px!important;
    padding-right: 0px !important;
    padding-top: 10px;
  }

  a {
    color: $white;
    font-family: $font;
    font-weight: $light;
    font-size: 16px;
  }
}

@media(max-width: 991px) {
  .footer-nav {
    text-align: center;
  }
  .social-nav {
    float: none!important;
    text-align: center;
    padding-left: 0!important;
  }

  .top-container {
    text-align: center!important;
      img {
        padding-bottom: 15px;
      }
  }

  .bottom-container {
    text-align: center!important;
  }
}

@media(max-width: 767px) {
  .footer-nav {
    .nav-item {
      display: block !important;
      text-align: center !important;
      a {
        text-align: center !important;
      }
    }
  }
}