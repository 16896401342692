@import "settings";

.navigation {
  font-family: $font;
  font-size: 16px;
  font-weight: $bold;


  .navbar {
    /*position: fixed;
    top:0;
    right: 0;
    left: 0;
    z-index: 1000;*/
    padding: 20px 0 20px 0;
    background: $black!important;
    /*height: 100px;*/

    .container {
      margin-top: 0px!important;
    }

    ul li  {
      margin-left: 50px;
    }

    .nav-link {
      color: $white!important;
      /*padding-top: 12px;*/

      &:hover,
      &:focus {
        color: $white!important;
        transition: .6s;
      }
    }

    .btn-item {
      .btn {
        background: $ColorA!important;
        padding: 12px 50px 12px 50px;
        color: #ffffff;
        font-weight: 700;
        border-radius: 50px!important;
        &:hover,
        &:focus {
          background: #ffffff!important;
          color: $ColorA;
          transition: all .8s ease-in-out;
        }
      }
    }

    .nav-link:after {
      content: '';
      display: block;
      width: 0;
      height: 3px;
      background-color: $white;
      transition: width .6s;
      margin-top: 0px!important;
    }

    .nav-link:hover:after {
      width: 100%;
      transition: width .6s;
    }

    .active:after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background-color: $white;
      margin-top: 0px!important;
    }
  }


  /*.nav-link-active:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #e60004;
    transition: width .5s;
    margin-top: 10px;
  }*/

  .fa-1x {
    font-size: 2rem;
  }
  .navbar-toggler.toggler-example {
    cursor: pointer;
  }
  .dark-red-text {
    color: $white;
  }

  .navbar-collapse {
    background: $black;
    z-index: 1;
    /*padding: 20px 10px 20px 10px;*/
  }

}

@media (max-width: 1200px) {
  ul li  {
    margin-left: 30px!important;
  }
}

@media(max-width: 991px) {
  .navbar-collapse {
    text-align: center!important;
  }

  .navigation {
    ul li  {
      margin-left: 0!important;
    }
  }
}

@media(max-width: 575px) {
  .navbar {
    .container {
      margin-left: 15px!important;
      margin-right: 15px!important;
    }
  }
}