@import "settings";

.main {
  margin-top:70px;
  margin-bottom:120px;
  h2 {
    margin: 0 auto!important;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $h2;
    font-weight: $extra;
    padding: 90px 0 90px 0;

  }
}

.content-top {
  text-align: center!important;
  padding: 20px 20px 20px 20px;
  margin: 0 10px 0 10px;
  h3 {
    font-family: $font;
    color: $black;
    font-size: $h3;
    font-weight: $bold;
    padding: 20px 15px 10px 15px;

  }

  p {
    font-family: $font;
    color: $lGrey;
    font-size: $p1;
    font-weight: $light;
  }

  border-bottom: 2px solid #ededed;
}

.content-bottom {
  text-align: center!important;
  margin-top: 35px;
  h3 {
    font-family: $font;
    color: $black;
    font-size: $h3;
    font-weight: $bold;
    padding: 20px 15px 10px 15px;
  }

  p {
    font-family: $font;
    color: $lGrey;
    font-size: $p1;
    font-weight: $light;
  }
}