@import "settings";

.header {
  background: #fbfbfb;
  h1 {
    color: $ColorA;
    text-transform: uppercase;
    font-size: $p1;
    text-align: center;
    font-weight: $bold;
    margin-top: 70px;
    margin-bottom: 50px;
  }

  .title {
    margin-top: 70px;
    margin-bottom: 0;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $title;
    font-weight: $bold;
    text-transform: none;
  }

  .sub-title {
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $title;
    font-weight: $regular;
    text-transform: none;
    padding: 0;
  }

  h2 {
    margin: 0 auto!important;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $h2;
    font-weight: $extra;
  }

  .post-title {
    max-width: 820px;
    padding-top: 30px;
    text-align: center!important;
    font-family: $font;
    color: $black;
    font-size: $post-title;
    font-weight: $bold;
  }

  p {
    max-width: 840px;
    color: $black;
    text-align: center;
    font-family: $font;
    font-weight: $light;
    font-size: $p1;
    margin: 0 auto;
    padding: 50px 0 50px 0;
  }

  .p-text {
    max-width: 840px;
    color: $black;
    text-align: center;
    font-family: $font;
    font-weight: $regular;
    font-size: $p-text;
    padding: 50px 0 0 0;
  }

  .inbox {
    text-align: center;
    position: relative;
    top: 40px;

    img {
      width: 300px;
      height: 526px;
    }
  }

  .inbox-event {
    text-align: center;
    position: relative;
    top: 40px;

    .event {
      width: 100%;
      height: auto;
    }
  }
}
@media(min-width: 515px) {
  .subp {
    max-height: 650px !important;
    margin-bottom: 100px;
  }
}

@media(max-width: 514px) {
  .subp {
    min-height: 710px!important;
    margin-bottom: 0!important;
  }

  .title {
    font-size: 25px!important;
  }

  .sub-title {
    font-size: $p-text!important;
  }

  .post-title {
    font-size: $p-text!important;
  }

  .post-header-top {
    margin-top:50px!important;
  }
}